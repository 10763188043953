import React from 'react'
import Grid from '@mui/material/Grid';
import MainTitle from '../components/MainTitle';
import SubTitle from '../components/SubTitle';
import Cert from '../components/Cert';

const Skills = ({
	certs,
	quotes,
	id,
	...props
}) => {
	return(
		certs ?
			<>
				<Grid id={id} container justify="center" spacing={1} alignItems="initial" >
					<Grid item xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
						<MainTitle title={'Certifications'} description={quotes && quotes.certs}/>
					</Grid>

					<Grid item xs={12} sm={12} md={12} style={{textAlign: 'initial'}}>
						<SubTitle style={{marginTop: '0rem'}} textAlign={'initial'} title='Professional'/>
					</Grid>
				</Grid>
				<Grid container justify="center" columnSpacing={3} rowSpacing={1} alignItems="initial">
					{certs.map((c,index)=>{
						return(
							<>	
								<Cert cert={c}/>
							</>
						);
					})}
				</Grid>
			</> : ''
		
	);
}

export default Skills;