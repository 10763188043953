import React from 'react'
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import MainTitle from '../components/MainTitle';
import SubTitle from '../components/SubTitle';

const ImageContainer = styled.div`
	text-align: 'center'
`;

const Label = styled.p`
	font-weight: bold;
	margin-bottom: 0rem;
	margin-top: 0rem;
`;

const Details = styled.p`
	margin-top: 0rem;
	margin-bottom: 0.5rem;
`;

const Image = styled.img`
	max-width: 85%;
	border: 14px solid rgba(84,155,217,0.3);
	border-radius: 50%;
`;

const Profile = ({
	quotes,
	profile,
	id,
	...props
}) => {
	return(
		<>
			<Grid id={id} container justify="center" spacing={1} >
				<Grid item xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
					<MainTitle title={'Profile'} description={profile.professionalTitle}/>
				</Grid>
				<Grid item xs={12} sm={12} md={4} style={{textAlign: 'initial'}}>
					<SubTitle textAlign={'initial'} title={'About Me'}/>
					<div style={{whiteSpace: 'pre-line'}}>
						{profile.aboutMe}
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={4} style={{textAlign: 'center'}}>
					<ImageContainer>
						<Image alt="AnkiPortraitImage" src={profile.profilePic}/>
					</ImageContainer>
				</Grid>
				<Grid item xs={0} sm={0} md={1} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
				</Grid>
				<Grid item xs={12} sm={12} md={3} style={{textAlign: 'initial'}}>
					<SubTitle textAlign={'initial'} title={'Details'}/>
					<Label>Name: </Label>
					<Details>{profile.details.name}</Details>
					<Label>Location: </Label>
					<Details>{profile.details.location}</Details>
					<Label>Work Status: </Label>
					<Details>{profile.details.workStatus}</Details>
				</Grid>
			</Grid>

				
		</>
	)
}

export default Profile;