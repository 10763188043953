import React from 'react'
import Grid from '@mui/material/Grid';
import MainTitle from '../components/MainTitle';
import Project from '../components/Project';

const Projects = ({
	projects,
	quotes,
	id,
	...props
}) => {

	return(
		<>
			<Grid id={id} container justify="center" spacing={1} alignItems="initial" >
				<Grid item xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
					<MainTitle title={'Freelance Projects'} description={quotes && quotes.projects}/>
				</Grid>
			</Grid>

			<Grid container justify="center" columnSpacing={3} rowSpacing={1} alignItems="initial">
				{projects.map((p,index)=>{
					return(
						<>	
							<Grid item xs={12} sm={12} md={6} style={{textAlign: 'initial'}}>
								<Project project={p}/>
							</Grid> 
						</>
					);
				})}
			</Grid>
		</>
	)
}

export default Projects;