import React from 'react';
import styled from 'styled-components';
import ScrollTo from "react-scroll-into-view";
// import {ViewingDeviceContext} from '../contexts/ViewingDeviceContext'
// import {ViewingDeviceViews} from '../utils.js'
import {Colors} from '../utils.js'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Tooltip from '@mui/material/Tooltip';

const NavBarContainer = styled.div`
	position: sticky;
	width: 10rem;
	float: right;
	right: 5%;
	top: 4rem;
`;

const Tab = styled.div`
	background-color: ${Colors.NavBar};
    color: ${Colors.NameTitle};
    font-size: 21px;
    padding: 2rem 0.75rem 2rem 0.75rem;
    cursor: pointer;
    font-weight: 500;
`;

const DownloadResume = styled.div`
	background-color: #F59C36;
    color: #6B4519;
    font-size: 20px;
    padding: 3px;
    cursor: pointer;
    font-weight: 500;
`;

const MouseOver = (event) => {
	event.target.style.color = Colors.NavBarHoverText;
}

const MouseOut = (event) => {
	event.target.style.color = Colors.NavBarText;
}

const NavBar = ({
	name,
	tabList,
	activeTab,
	setActiveTab,
	...props
}) => {

	// const {viewingDevice, windowHeight, windowWidth} = useContext(ViewingDeviceContext);
	// const [activeTab, setActiveTab] = useState('Profile');

	const handleClick = (event) => {
		setActiveTab(event.target.id);
	}

	const fileName = "/" + name.replaceAll(/\s/g,'') + "_Resume.pdf"

	return(
		<NavBarContainer>
			<DownloadResume 
				key={'DownloadResume'}
				id={`DownloadResumeTab`}
				style={{}}
				onClick={handleClick} 
				onMouseOver={MouseOver} 
				onMouseOut={MouseOut}>
					<Tooltip placement="top" title="Download Resume">
						<a href={fileName} style={{color: 'unset'}} download>
							<DownloadForOfflineIcon style={{fontSize: '40px'}}/>
						</a>
					</Tooltip>
			</DownloadResume>
			{tabList.map((cur)=>{
				const tabName = cur + 'Tab';
				return(
					<>	
						<ScrollTo key={cur + 'Section'} selector={`#${cur}Section`}>
							<Tab 
								key={cur}
								id={`${cur}Tab`}
								style={{backgroundColor: tabName === activeTab ? Colors.SubTitle : Colors.NavBar}}
								onClick={handleClick} 
								onMouseOver={tabName !== activeTab ? MouseOver : ''} 
								onMouseOut={MouseOut}>
									{cur}
							</Tab>
						</ScrollTo>
					</>
				);
			})}
			
		</NavBarContainer>
	)
}

export default NavBar;