import React, {useContext} from 'react';
import styled from 'styled-components';
import {Colors} from '../utils.js';
// import './ComponentStyles.css';
import {ViewingDeviceContext} from '../contexts/ViewingDeviceContext'
import {ViewingDeviceViews} from '../utils.js'

const MainTitle = ({
	title,
	description,
	textAlign,
	descAlign,
	...props
}) => {
	const {viewingDevice} = useContext(ViewingDeviceContext);

	const Title = styled.h1`
		font-family: sans-serif;
		text-align: ${textAlign ? textAlign : 'center'};
		font-size: ${viewingDevice === ViewingDeviceViews.MOB? '3rem' : '3.5rem'};
		font-weight: lighter;
		letter-spacing: 1px;
		color: ${Colors.MainTitle}
	`;

	const Desc = styled.div`
		max-width: 40rem;
	    overflow-wrap: break-word;
		text-align: ${descAlign ? descAlign : 'center'};
	    color: rgba(0,0,0,0.60);
	    margin: auto;
	    position: relative;
	    top: -2rem;
	    // font-family: NexaBold;
	    letter-spacing: 1.6px;
	    line-height: 25px;
	    font-size: large;
	    padding-left: 4px;
	`;

	return(
		<div>
			<Title>
				{title}
			</Title>
			<Desc>
				{description}
			</Desc>
		</div>
	)
};

export default MainTitle;