import './App.css';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import Portfolio from './pages/Portfolio';
import PortfolioContextProvider from './contexts/PortfolioContext';
import ViewingDeviceContextProvider from './contexts/ViewingDeviceContext';
import PhotographyHome from './pages/photography/PhotographyHome';


const App =() => {
	return (
		<BrowserRouter>
	    	<ViewingDeviceContextProvider>
				<PortfolioContextProvider>
				<div className="App">
					<Routes>
						<Route exact path='/' element={<Portfolio/>}/>
						<Route exact path='/photography' element={<PhotographyHome/>}/>
					</Routes>
					</div>
	      		</PortfolioContextProvider>
	      	</ViewingDeviceContextProvider>
	    </BrowserRouter>
	);
}

export default App;
