import React, {useState} from 'react';
import styled from 'styled-components';
// import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { send } from 'emailjs-com';
import Alert from '@mui/material/Alert';
import './ComponentStyles.css';

const InfoWrapper = styled.div`
	margin-bottom: 1.5rem;
`;

const SectionWrapper = styled.div`
	margin: 1.5rem;
`;

const Contact = () => {
	const servideId = process.env.REACT_APP_EMAIL_SERVICE_ID;
	const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
	const userId = process.env.REACT_APP_EMAIL_SERVICE_USER_ID;

	const [formData, setFormData] = useState({
		contactName:'',email:'',message:'',phone:''
	});
	const [successAlert, setSuccessAlert] = useState(false);
	const [failureAlert, setFailureAlert] = useState(false);

	const handleSubmit = (e) => {
		console.log("Submit clicked");
		e.preventDefault();
		setFormData({contactName:'',email:'',message:'',phone:''});

		// setSuccessAlert(true);
		// setFailureAlert(true);
			
				
		send(servideId,templateId,formData,userId)
	      .then((response) => {
	        console.log('SUCCESS!', response.status, response.text);
	        setSuccessAlert(true);
	        setFormData({contactName:'',email:'',message:'',phone:''});
	      })
	      .catch((err) => {
	      	setFailureAlert(true);
	        console.log('FAILED...', err);
	      });

		 setTimeout(
		    function() {
		        setSuccessAlert(false);
		        setFailureAlert(false);
		    },
		    3000
		);
	}

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	return(
		<>
			{successAlert ? 
				<Alert severity="success" style={{display: 'inline-flex', position:'fixed', top: '1rem', right:'1rem', animation: 'fadein 1s ease-in, fadeout 2s ease-in 4s', animationFillMode: 'forwards'}}>
					Contact request submitted successfully!
				</Alert> : ''
			}
			{failureAlert ? 
				<Alert severity="warning" style={{display: 'inline-flex', position:'fixed', top: '1rem', right:'1rem', animation: 'fadein 1s ease-in, fadeout 2s ease-in 3s', animationFillMode: 'forwards'}}>
					Contact request failed! Please try again.
				</Alert> : ''
			}

			<p style={{textAlign: 'justify'}}>
				<SectionWrapper>
					<form onSubmit={handleSubmit} >
						<InfoWrapper>
							<TextField
								fullWidth required
								autoFocus={false}
								id="name"
								name="contactName"
								label="Name"
								defaultValue=""
								variant="filled"
								color="info"
								value={formData.contactName}
								onChange={handleChange}
					        />
						</InfoWrapper>
						<InfoWrapper>
							<TextField
								fullWidth required
								autoFocus={false}
								id="email"
								name="email"
								label="Email"
								defaultValue=""
								variant="filled"
								color="info"
								// value={email}
								// onInput={ e=>setEmail(e.target.value)}
								value={formData.email}
								onChange={handleChange}
					        />
						</InfoWrapper>
						<InfoWrapper>
							<TextField
								fullWidth
								autoFocus={false}
								id="phone"
								name="phone"
								label="Phone"
								defaultValue=""
								variant="filled"
								color="info"
								value={formData.phone}
								onChange={handleChange}
					        />
						</InfoWrapper>
						<InfoWrapper>
							<TextField
								fullWidth required multiline
								autoFocus={false}
								rows={4}
								id="message"
								name="message"
								label="Message"
								defaultValue=""
								variant="filled"
								color="info"
								// value={message}
								// onInput={ e=>setMessage(e.target.value)}
								value={formData.message}
								onChange={handleChange}
					        />
						</InfoWrapper>
						<InfoWrapper style={{float:'left'}}>
							<Button 
								disabled={formData.contactName && formData.email && formData.message ? false : true}
								type="submit"
								variant="contained"
								color="inherit"
								size="large"
								onClick={handleSubmit}
								sx={{backgroundColor:"#222", color: "#ddd"}}
							>
								Submit
							</Button>
						</InfoWrapper>
					</form>
				</SectionWrapper>
			</p>
		</>
	);
}

export default Contact;