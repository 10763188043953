import React from 'react'
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
// import {Colors} from '../utils.js';
import LocalTitle from './LocalTitle';


const CertImage = styled.img`
	width: 90%;
`;


const CertStatus = styled.span`
	color: #4FD63A;
`;

const CertExp = styled.span`

`;

const CertId = styled.span`

`;


const CertChecker = styled.a`

`;

const Label = styled.span`

`;

const Detail = styled.div`

`;


const Skill = ({
	cert
}) => {
	return(
		<>
			<Grid item xs={3} sm={3} md={3} sx={{textAlign: 'initial'}}>
				<CertImage alt={`certImage`+cert.certName} src={cert.certImage}/>
			</Grid>
			<Grid item xs={0} sm={1} md={1} sx={{textAlign: 'initial', display: { xs: 'none', sm: 'block', md: 'block'}}}/>
			<Grid item xs={9} sm={8} md={8} sx={{textAlign: 'initial', margin: 'auto'}}>
				<Detail>
					<LocalTitle title={cert.certName}  style={{margin:'0px'}}/>
				</Detail>

				<Detail>
					<Label>CertId: &nbsp;</Label>
					<CertId>{cert.certId}</CertId>
				</Detail>
				
				<Detail>
					<Label>Status: &nbsp;</Label>
					<CertStatus>{cert.status}</CertStatus>
				</Detail>
				
				<Detail>
					<Label>Validity: &nbsp;</Label>
					<CertExp>{cert.validity}</CertExp>
				</Detail>
				
				<Detail>
					<CertChecker href={cert.validationChecker} rel="noreferrer" target="_blank">Click Here</CertChecker> to verify
				</Detail>
			</Grid>
		</>
	)
}

export default Skill;