export const ViewingDeviceViews = Object.freeze({
    MOB: "MobileView",
    DESK: "DesktopView"
});

export const Colors = Object.freeze({
	FontColor: "#555566",
	MainTitle: "cadetblue",
	SubTitle: "cadetblue",
	NameTitle: "#ffffff",
	NavBar: "#333333",
	NavBarText: "#ffffff",
	NavBarHoverText: "#aaaaaa",
	LocalTitle: "444455",
	Background1: "aliceblue",
	Background2: "seashell",
	Background3: "linen"
});