import React from 'react';
import Container from '@mui/material/Container';
import styled from 'styled-components'

const PContainer = styled(Container)({
	position: 'relative',
});

const PageContainer = (props) => {
	return(
		<PContainer fixed style={props.style}>
			{props.children}
		</PContainer>
		);
}

export default PageContainer;