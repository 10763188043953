import React,{useState} from 'react'
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalTitle from './LocalTitle'


const JobTitle = styled.p`
	font-weight: bold;
`;

const JobDesc = styled.p`

`;

const Duration = styled.p`
	margin-top: 0.5rem;
	font-style: italic;
	opacity: 0.7
`;

const JobDetails = styled.ul`

`;

const Tags = styled.p`

`;

const CourseworkTitle = styled.p`
	font-weight: bold;
	margin: 0.5rem 0 0.5rem -1rem;
`;

const TagsTitle = styled.span`
	font-weight: bold;
`;

const AllTags = styled.span`
	font-style: italic;
`;

const ShowMore = styled.span`
	color: deepskyblue;
	cursor: pointer;
	display: flex;
    align-items: center;
    flex-wrap: wrap;
`;



const Experience = ({
	companyName,
	duration,
	jobTitle,
	jobDesc,
	jobDetails,
	key,
	tags
}) => {

	const [showDetails,setShowDetails] = useState(false);
	const handleShowDetails = () => {
		setShowDetails(!showDetails);
	}

	return(
		<>
			<Grid item xs={12} sm={12} md={3} style={{textAlign: 'initial'}}>
				<LocalTitle style={{marginBottom: '0rem'}} title={companyName}/>

				<Duration>
					{duration}
				</Duration>
			</Grid>
			<Grid item md={1} style={{textAlign: 'initial', xs:{display: 'none'}, sm:{display: 'none'}}}/>
			<Grid item xs={12} sm={12} md={8} style={{textAlign: 'initial'}}>
				<JobTitle>
					{jobTitle}
				</JobTitle>
				<JobDesc>
					{jobDesc}
				</JobDesc>
				{tags ?
					<Tags>
						<TagsTitle>Tags: </TagsTitle> 
						<AllTags>{tags}</AllTags>
					</Tags> : ''
				}

				{showDetails  ?  '' : 
					(jobDetails && jobDetails.length > 0 ? 
						<ShowMore onClick={handleShowDetails}>
							Show More <KeyboardArrowDownIcon/>
						</ShowMore> : ''
					)
				}

				{showDetails ?
					<>
						<ShowMore onClick={handleShowDetails}>Show Less <KeyboardArrowUpIcon/></ShowMore>
						<JobDetails>
							<CourseworkTitle>Details:</CourseworkTitle>
							{jobDetails.map((cur)=>{
								console.log(cur);
								return(
									<>
										<li>{cur}</li>
									</>
								);
							})}		
						</JobDetails>
					</> : ''
				}
			</Grid>
		</>
	)
}

export default Experience;