import React from 'react'
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import {Colors} from '../utils.js'

const SkillName = styled.div`
	
`;

const SkillLevel = styled.span`
	display: block;
	color: ${Colors.MainTitle}
`;


const Skill = ({
	skillLevel,
	skill
}) => {
	// console.log("skill: ",skill,", skillLevel",skillLevel);
	let sl = skillLevel;
	return(
		<>
			<Grid container justify="center" spacing={1} alignItems="initial" >
				<Grid item xs={6} sm={6} md={6}>
					<SkillName>{skill}</SkillName>
				</Grid>
				<Grid item xs={6} sm={6} md={6}>

					<SkillLevel>
						
						{[...Array(5)].map((i)=>{

							sl-=1;
							if(sl == 0.5)
								return(
									<>
										<StarHalfIcon key={skill}/>
									</>
								);
							else if(sl >= 0)
								return(
									<>
										<StarIcon key={skill}/>
									</>
								);
							else 
								return(
									<>
										<StarBorderIcon key={skill}/>
									</>
								);
						})}


					</SkillLevel>
				</Grid>
			</Grid>
		</>
	)
}

export default Skill;