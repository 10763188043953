import React,{useState,useContext,useEffect} from 'react'
import styled from 'styled-components'
import Profile from './Profile'
import Projects from './Projects'
import Skills from './Skills'
import Experiences from './Experiences'
import Certs from './Certs'
import Contact from './Contact'
import {PortfolioContext} from '../contexts/PortfolioContext';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import {Colors} from '../utils.js'
import {ViewingDeviceContext} from '../contexts/ViewingDeviceContext'
import {ViewingDeviceViews} from '../utils.js'
import NavBar from '../components/NavBar'
import MobileNav from '../components/MobileNav'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import portfolio from '../data/portfolio.json';

const NavArrow = styled.div`
	position: absolute;
    width: 10rem;
    height: 4rem;
    bottom: 1rem;
    right: 5%;
    display: block;
    padding: 1rem 0rem 1rem 0rem;
    background-color: ${Colors.NavBar};
    color: ${Colors.NameTitle};
`;


const Portfolio = () => {
	const {portfolio} =  useContext(PortfolioContext);
	const {viewingDevice, windowHeight, windowWidth} = useContext(ViewingDeviceContext);
	const [loading,setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState('');
	let profileElement,experiencesElement,skillsElement,certsElement,projectsElement,contactElement;

	const tabList = ["Profile","Experiences","Skills","Certs","Projects","Contact"];
	useEffect(() => {
		profileElement = document.getElementById('ProfileSection').getBoundingClientRect();
		experiencesElement = document.getElementById('ExperiencesSection').getBoundingClientRect();
		skillsElement = document.getElementById('SkillsSection').getBoundingClientRect();
		projectsElement = document.getElementById('ProjectsSection').getBoundingClientRect();
		certsElement = document.getElementById('CertsSection').getBoundingClientRect();
		contactElement = document.getElementById('ContactSection').getBoundingClientRect();

		// console.log("profile: ",profileElement.y)
		// console.log("experiences: ",experiencesElement.y)
		// console.log("skills: ",skillsElement.y)
		// console.log("Certs: ",certsElement.y)
		// console.log("Projects: ",projectsElement.y)
		// console.log("Contact: ",contactElement.y)
	}, []);


	const handleScroll = (event) => {
		console.log("Handling scroll")
	    // let scrollTop = document.body.scrollTop;
	    // const scrollTop = window.pageYOffset - windowHeight;
	    const scrollTop = window.scrollY-windowHeight;
	    // let itemTranslate = Math.min(0, scrollTop/3 - 60);

		// console.log("scrollTop: ",scrollTop);
	    // Switch is slower
		// if(scrollTop<=profileElement.y)
		// 	setActiveTab('ProfileTab');
		// else if(scrollTop<=experiencesElement.y && activeTab!='ExperiencesTab')
		// 	setActiveTab('ExperiencesTab');
		// else if(scrollTop<=skillsElement.y && activeTab!='SkillsTab')
		// 	setActiveTab('SkillsTab');
		// else if(scrollTop<=certsElement.y && activeTab!='CertsTab')
		// 	setActiveTab('CertsTab');
		// else if(scrollTop<=projectsElement.y && activeTab!='ProjectsTab')
		// 	setActiveTab('ProjectsTab');
		// else if(scrollTop<=contactElement.y && activeTab!='ContactTab')
		// 	setActiveTab('ContactTab');
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	},[]);

	if(portfolio && Object.keys(portfolio).length === 0){
		setLoading(true);
	}

	if(loading){
		return (
		    <Box sx={{ display: 'flex' }}>
		      <CircularProgress sx={{margin: 'auto'}}/>
		    </Box>
		);
	}


	const SectionWrapper = styled.div`
		margin: auto;
		padding: ${viewingDevice === ViewingDeviceViews.MOB? '3rem 10% 3rem 10%' : '3rem 20% 3rem 20%'};
		line-height: 1.6;
		color: ${Colors.FontColor};
	`;
	const BackgroundImage = styled.div`
		min-height: ${windowHeight + 'px'};
		margin-top: -1rem;
		background-image: url(${portfolio.backgroundImage});
		background-position: center;
	  	background-repeat: no-repeat;
	  	background-size: cover;
	  	background-attachment: fixed;
	  	background-position-y: initial;
	  	opacity: 0.6
	`;
	const NameSection = styled.div`
		background: none;
		position: absolute;
		font-family: fangsong;
		font-size: 5rem;
		font-weight: bolder;
		letter-spacing: 4px;
		color: #ffffff;
		width: fit-content;
		
		top: ${windowHeight/2.5 + 'px'};
		left: ${viewingDevice === ViewingDeviceViews.MOB ? 'unset' : windowWidth/2.7 + 'px'};
		right: ${viewingDevice === ViewingDeviceViews.MOB ? 'unset' : windowWidth/2.7 + 'px'};
	`;

	return(
		<>
			<BackgroundImage>
				{viewingDevice === ViewingDeviceViews.DESK ?
				<NavArrow>
					<KeyboardArrowDownIcon style={{fontWeight: "bolder", fontSize: "4rem"}}/>
				</NavArrow>  : ''}
			</BackgroundImage>
			

			{
				viewingDevice === ViewingDeviceViews.MOB ? 
					<MobileNav
						name={portfolio.name}
						tabList={tabList}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/> :
					<NavBar
						name={portfolio.name}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						tabList={tabList}
					/>
			}

			{
				portfolio.name?
					<NameSection id={"nameSection"}>
						<span>{portfolio.name}</span>
					</NameSection>:''
			}
			
			{
				portfolio.profile ?
					<SectionWrapper style={{backgroundColor: Colors.Background1}}>
						<Profile 
							id={"ProfileSection"}
							quotes={portfolio.quotes}
							profile={portfolio.profile}
						/>
					</SectionWrapper> : ''
			}

			{
				(portfolio.career || portfolio.education) ?
					<SectionWrapper style={{backgroundColor: Colors.Background2}}>
						<Experiences 
							id={"ExperiencesSection"}
							education={portfolio.education}
							quotes={portfolio.quotes}
							career={portfolio.career}
						/>
					</SectionWrapper> :''
			}

			{
				portfolio.skills ?
					<SectionWrapper style={{backgroundColor: Colors.Background1}}>
						<Skills
							id={"SkillsSection"}
							quotes={portfolio.quotes}
							skillList={portfolio.skills}
							languages={portfolio.languages}
						/>
					</SectionWrapper> : ''
			}

			{
				portfolio.certs ?
					<SectionWrapper style={{backgroundColor: Colors.Background2}}>
						<Certs
							id={"CertsSection"}
							quotes={portfolio.quotes}
							certs={portfolio.certs}
						/> 
					</SectionWrapper> : ''
			}

			{
				portfolio.projects ?
					<SectionWrapper style={{backgroundColor: Colors.Background1}}>
						<Projects
							id={"ProjectsSection"}
							quotes={portfolio.quotes}
							projects={portfolio.projects}
						/>
					</SectionWrapper> : ''
			}

			{
				portfolio.social ?
					<SectionWrapper style={{backgroundColor: Colors.Background2}}>
						<Contact
							id={"ContactSection"}
							quotes={portfolio.quotes}
							social={portfolio.social}
						/>
					</SectionWrapper> : ''
			}

		</>
	)
};

export default Portfolio;