import React, { useState, useEffect, createContext} from 'react'
import {ViewingDeviceViews} from '../utils.js' 

export const ViewingDeviceContext = createContext();

const ViewingDeviceContextProvider = (props) => {
	const [viewingDevice,setViewingDevice] = useState(ViewingDeviceViews.DESK);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	// console.log("In Context. windowHeight: ",windowHeight);
	useEffect(() => {
	    const setResponsiveness = () => {
	    	setWindowHeight(window.innerHeight);
	    	setWindowWidth(window.innerWidth);
	      return window.innerWidth < 900
	        ? setViewingDevice(ViewingDeviceViews.MOB)
	        : setViewingDevice(ViewingDeviceViews.DESK);
	    };

	    setResponsiveness();
	    window.addEventListener("resize", () => setResponsiveness());

	    return () => {
	      window.removeEventListener("resize", () => setResponsiveness());
	    }
	  }, []);

	return(
		<ViewingDeviceContext.Provider value={{viewingDevice, windowHeight, windowWidth}}>
			{props.children}
		</ViewingDeviceContext.Provider>
	);
}

export default ViewingDeviceContextProvider;
