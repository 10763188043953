import React,{useState} from 'react'
import styled from 'styled-components'
// import Grid from '@mui/material/Grid'
// import {Colors} from '../utils.js'
import LocalTitle from './LocalTitle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Thumbnail = styled.img`
	width: 90%;
	cursor: pointer
`;

const ThumbnailContainer = styled.a`
`;

const Summary = styled.div`
	font-style: italic;
`;

const Desc = styled.div`
`;

const Tags = styled.div`
	margin-top: 1rem;
`;

const Improvements = styled.div`
	margin-top: 1rem;
`;

const TechDetails = styled.div`
	margin-top: 1rem;
`;

const ProjectDetails = styled.div`
`;

const ShowMore = styled.span`
	color: deepskyblue;
	cursor: pointer;
	display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const MiniTitle = styled.span`
	font-weight: bold;
`;

const AllTags = styled.span`
	font-style: italic;
`;


const Project = ({
	project
}) => {

	const [showDetails,setShowDetails] = useState(false);
	const handleShowDetails = () => {
		setShowDetails(!showDetails);
	}

	return(
		<>
			<LocalTitle 
				title= {project.title} 
				description={"("+project.date+")"}
				descAlign='initial'
				style={{}}
			/>
			

			<ThumbnailContainer href={project.link} rel="noreferrer" target="_blank">
				<Thumbnail alt={`ProjectThumbnail`+project.title} src={project.image}/>
			</ThumbnailContainer>
			<Summary>{project.summary}</Summary>

			{showDetails  ?  '' : 
				(project && (project.desc || project.techDetails || project.tags) ? 
					<ShowMore onClick={handleShowDetails}>
						Show More <KeyboardArrowDownIcon/>
					</ShowMore> : ''
				)
			}

			{showDetails ?
				<>
					<ShowMore onClick={handleShowDetails}>Show Less <KeyboardArrowUpIcon/></ShowMore>
					<ProjectDetails>
						<Desc>{project.desc}</Desc>
						<TechDetails>
							<MiniTitle>Tech Details: </MiniTitle>
							{project.techDetails}
						</TechDetails>
						{project.tags ?
							<Tags>
								<MiniTitle>Tags: </MiniTitle> 
								<AllTags>{project.tags}</AllTags>
							</Tags> : ''
						}
						{project.improvements ?
							<Improvements>
								<MiniTitle>Future Enhancements: </MiniTitle> 
								{project.improvements.map((cur)=>{
									return(
										<>
											<li>{cur}</li>
										</>
									);
								})}		
							</Improvements> : ''
						}			
					</ProjectDetails>
				</> : ''
			}
			
		</>
	)
}

export default Project;