import React from 'react'
import Grid from '@mui/material/Grid';
import MainTitle from '../components/MainTitle';
import ContactForm from '../components/ContactForm';
import SocialMedia from '../components/SocialMedia';

const Contact = ({
	social,
	quotes,
	id,
	...props
}) => {


	return(
		<>
			<Grid id={id} container justify="center" spacing={1} alignItems="initial" >
				<Grid item xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
					<MainTitle title={'Contact'} description={quotes && quotes.contact}/>
				</Grid>
			</Grid>
			<Grid container justify="center" columnSpacing={5} rowSpacing={1} alignItems="initial">
				<Grid item xs={12} sm={12} md={6} style={{textAlign: 'initial'}}>
					<ContactForm/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} style={{textAlign: 'end', alignItems:"end"}}>
					{Object.keys(social).map((s,index)=>{
						let sLink = social[s];
						return(
							<>	
								<SocialMedia social={s} socialLink={sLink}/>
							</>
						);
					})}
				</Grid>
			</Grid>
		</>
	)
}

export default Contact;