import React from 'react'
import Grid from '@mui/material/Grid';
import MainTitle from '../components/MainTitle';
import SubTitle from '../components/SubTitle';
import Skill from '../components/Skill';

const Skills = ({
	skillList,
	quotes,
	languages,
	id,
	...props
}) => {
	// console.log("skillList: ");
	// console.log(skillList);
	return(
		<>
			<Grid id={id} container justify="center" spacing={1} alignItems="initial" >
				<Grid item xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
					<MainTitle title={'Abilities'} description={quotes && quotes.skills}/>
				</Grid>
				<Grid item xs={12} sm={12} md={12} style={{textAlign: 'initial'}}>
					<SubTitle style={{marginTop: '0rem'}} textAlign={'initial'} title='Skills'/>
				</Grid>
			</Grid>
			<Grid container justify="center" columnSpacing={3} rowSpacing={1} alignItems="initial">
				{Object.keys(skillList).map((s,index)=>{
					let sl = skillList[s];
					return(
						<>	
							<Grid item xs={12} sm={12} md={6} style={{textAlign: 'initial'}}>
								<Skill skill={s} skillLevel={sl}/>
							</Grid> 
						</>
					);
				})}
			</Grid>
		</>
	)
}

export default Skills;