import React,{useState} from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import ScrollTo from "react-scroll-into-view";
import {Colors} from '../utils.js'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';



const MobNavContainer = styled.div`
	position: fixed;
    top: 2%;
    right: 2%;
    width: 100%
`;


const BubbleContainer = styled.div`
    // background-color: #F59C36;
    background-color: #444444;
    opacity: 0.6;
    color: #eeeeee;
    padding: 6px;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
`;

const DownloadResume = styled.div`
    padding: 4px;
`;

const MouseOver = (event) => {
	event.target.style.color = Colors.NavBarHoverText;
}

const MouseOut = (event) => {
	event.target.style.color = Colors.NavBarText;
}

const ITEM_HEIGHT = 48;

const MobileNav = ({
	name,
	tabList,
	activeTab,
	setActiveTab,
	...props
}) => {

	const fileName = "/" + name.replaceAll(/\s/g,'') + "_Resume.pdf"

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
	    setAnchorEl(event.currentTarget);
	};
	const optionClick = (event) => {
		console.log("Clicked ID: ", event.target.id);
		setActiveTab(event.target.id);

		setTimeout(
		    function() {
		        setAnchorEl(null);
		    },
		    1000
		);
		// setAnchorEl(null);
	};
	const handleClose = (event) => {
	    setAnchorEl(null);
	};

	return(
		<MobNavContainer>
			<Grid sx={{}} container justify="center" spacing={0} alignItems="initial" >
				<Grid item xs={8} sm={8} md={8} style={{textAlign: 'center'}}>
				</Grid>
				<Grid item xs={2} sm={2} md={2} style={{textAlign: 'center'}}>
					<BubbleContainer>
						<DownloadResume 
							key={'DownloadResume'}
							id={`DownloadResumeButton`}
							style={{}}
							onMouseOver={MouseOver} 
							onMouseOut={MouseOut}>
								<a href={fileName} style={{color: 'unset'}} download>
									<DownloadForOfflineIcon style={{fontSize: '40px'}}/>
								</a>
						</DownloadResume>
					</BubbleContainer>
				</Grid>
				<Grid item xs={2} sm={2} md={2} style={{textAlign: 'center'}}>
					<BubbleContainer>
				      <IconButton
				        aria-label="more"
				        id="long-button"
				        aria-controls="long-menu"
				        aria-expanded={open ? 'true' : undefined}
				        aria-haspopup="true"
				        onClick={handleClick}

				      >
				        <MenuIcon sx={{padding: '4px', color: '#ffffff'}} />
				      </IconButton>
				      <Menu
				        id="long-menu"
				        MenuListProps={{
				          'aria-labelledby': 'long-button',
				        }}
				        anchorEl={anchorEl}
				        open={open}
				        onClose={handleClose}
				        PaperProps={{
				          style: {
				            maxHeight: ITEM_HEIGHT * 4.5,
				            width: '20ch',
				          },
				        }}
				      >
				        {tabList.map((tab) => {
				        	return(
				        		<>
					        	<ScrollTo key={tab} selector={`#${tab}`}>
						        	<MenuItem 
						        		key={tab}
						        		id={`${tab}Tab`}				
						        		onClick={optionClick}
						        	>
						            	{tab}
						        	</MenuItem>
						        </ScrollTo>
						        </>
					        )
				        })}
				      </Menu>
					</BubbleContainer>
				</Grid>
			</Grid>
		</MobNavContainer>
	)
}

export default MobileNav;

