import React, { useState, createContext} from 'react';
import pf from '../data/portfolio.json';

export const PortfolioContext = createContext();

const PortfolioContextProvider = (props) => {

	const [portfolio,setPortfolio] = useState(pf);

	return(
		<PortfolioContext.Provider value={{portfolio}}>
			{props.children}
		</PortfolioContext.Provider>
	);
}

export default PortfolioContextProvider;
