import React from 'react'
import PageContainer from '../../components/PageContainer'

const PhotographyHome = () => {
	return(
		<>
			<PageContainer>
				<h1>Photography Home</h1>
			</PageContainer>
		</>
	)
}

export default PhotographyHome;