import React from 'react'
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
// import CopyAllIcon from '@mui/icons-material/CopyAllRounded';
import {Colors} from '../utils.js'


const ContactContainer = styled.a`
	margin-top: 1rem;
	text-decoration: none;
`;

const SocialMedia = ({
	social,
	socialLink
}) => {

	return(
		<>
			{socialLink ?
				<ContactContainer href={socialLink} rel="noreferrer" target="_blank">
					<Grid container justify="center" spacing={1} alignItems="initial" style={{marginBottom: '1rem', marginTop: '1rem',color: Colors.FontColor}}>
						<Grid item xs={2} sm={2} md={2} style={{textAlign: 'initial'}}>
							{
								(social === "linkedIn") ? <LinkedInIcon/> : 
								(social === "email" ? <AlternateEmailIcon/> :
								(social === "facebook" ? <FacebookIcon/> : 
								(social === "instagram" ? <InstagramIcon/> :
								(social === "twitter" ? <TwitterIcon/> : 
								(social === "github" ? <ConnectWithoutContactIcon/> : <ConnectWithoutContactIcon/>)))))
							}
						</Grid>
						<Grid item xs={9} sm={9} md={9} style={{textAlign: 'initial'}}>
							{socialLink}
						</Grid>
					</Grid>
				</ContactContainer> : ''
			}
		</>
	)

}

export default SocialMedia;