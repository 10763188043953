import React from 'react'
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import MainTitle from '../components/MainTitle';
import SubTitle from '../components/SubTitle';
import Experience from '../components/Experience';
// import {Colors} from '../utils.js'

const Exp = styled.div`
`;

const Experiences = ({
	education,
	career,
	quotes,	
	id,
	...props
}) => {

	return(
		<Exp >
			<Grid id={id} container justify="center" spacing={1} alignItems="initial" >
				<Grid item xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
					<MainTitle title={'Experiences'} description={quotes.experiences}/>
				</Grid>
				<Grid item xs={12} sm={12} md={12} style={{textAlign: 'initial'}}>
					<SubTitle style={{margin: '0rem'}} textAlign={'initial'} title={'Career'}/>
				</Grid>

				{career.map((cur)=>{
					return(
						<>
							<Experience
								key={cur.companyName}
								companyName={cur.companyName}
								duration={cur.duration}
								jobTitle={cur.jobTitle}
								jobDesc={cur.jobDesc}
								jobDetails={cur.jobDetails}
								tags={cur.tags}
							/>
						</>
					);
				})}

				<Grid item xs={12} sm={12} md={12} style={{textAlign: 'initial'}}>
					<SubTitle style={{margin: '0rem'}} textAlign={'initial'} title={'Education'}/>
				</Grid>
				{education.map((cur)=>{
					return(
						<>
							<Experience
								companyName={cur.collegeName}
								duration={cur.graduation}
								jobTitle={cur.degree}
								jobDesc={cur.courseDesc}
								jobDetails={cur.coursework}
								tags={cur.tags}
							/>
						</>
					);
				})}		
			</Grid>
		</Exp>
	)
}

export default Experiences;