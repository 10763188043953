import React, {/*useContext*/} from 'react';
import styled from 'styled-components'
import {Colors} from '../utils.js';

// import './ComponentStyles.css';
// import {ViewingDeviceContext} from '../contexts/ViewingDeviceContext'
// import {ViewingDeviceViews} from '../utils.js'

const LocalTitle = ({
	title,
	description,
	textAlign,
	descAlign,
	style,
	...props
}) => {
	// const {viewingDevice} = useContext(ViewingDeviceContext);

	const Title = styled.h4`
		font-family: sans-serif;
		text-align: ${textAlign ? textAlign : 'initial'};
		font-weight: bold;
		font-size: 20px;
		letter-spacing: 1px;
		color: ${Colors.LocalTitle}
	`;

	const Desc = styled.div`
		max-width: 40rem;
	    overflow-wrap: break-word;
		text-align: ${descAlign ? descAlign : 'center'};
	    color: rgba(0,0,0,0.60);
	    margin: auto;
	    position: relative;
	    top: -2rem;
	    // font-family: NexaBold;
	    letter-spacing: 1.6px;
	    line-height: 25px;
	    font-size: large;
	    padding-left: 4px;
	`;

	return(
		<div>
			<Title style={style}>
				{title}
			</Title>
			<Desc>
				{description}
			</Desc>
		</div>
	)
};

export default LocalTitle;